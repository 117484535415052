import React, { Component } from 'react';
import { BrowserRouter } from 'react-router-dom';
import configureStore from './config/configureStore';
import { Provider } from 'react-redux';
import Routes from './Routes';
import ScrollToTop from './utils/ScrollToTop';
import './assets/base.scss';
import CssBaseline from '@material-ui/core/CssBaseline';

import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const store = configureStore();

class App extends Component {
	componentDidMount() {
		document.body.style.backgroundColor = '#dde7ed';
		console.log('Build:' & process.env.CIRCLE_BUILD_NUM);
		console.log('Branch:' & process.env.CIRCLE_BRANCH);
	}

	render() {
		return (
			<Elements stripe={stripePromise}>
				<Provider store={store}>
					<BrowserRouter>
						<CssBaseline />
						<ScrollToTop>
							<Routes />
						</ScrollToTop>
					</BrowserRouter>
				</Provider>
			</Elements>
		);
	}
}

export default App;
