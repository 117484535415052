import React from 'react';
import ReactDOM from 'react-dom';

import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

import App from './App';
import * as serviceWorker from './serviceWorker';

import './i18n';

import { disableReactDevTools } from '@fvilers/disable-react-devtools';

if (process.env.REACT_APP_ENV === 'PROD') {
	// disable React Dev Tools for production
	disableReactDevTools();

	// deactivate logging on PROD
	console.log = () => {};

	// enable sentry error logging
	Sentry.init({
		dsn: 'https://036ad87fab19444cadf9eb1ad1a86264@o1071225.ingest.sentry.io/6068031',
		integrations: [new Integrations.BrowserTracing()],

		// Set tracesSampleRate to 1.0 to capture 100%
		// of transactions for performance monitoring.
		// We recommend adjusting this value in production
		tracesSampleRate: 1.0,
	});
}

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
