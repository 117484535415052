import React, { lazy, Suspense, Fragment } from 'react';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';

import { ThemeProvider } from '@material-ui/styles';

import { ClipLoader } from 'react-spinners';

import MuiTheme from './theme';

import { PresentationLayout } from './layout-blueprints';

const SuccessPage = lazy(() => import('./pages/success'));
const CancelPage = lazy(() => import('./pages/cancel'));
// const StripeTest = lazy(() => import("./pages/stripeTest"));
const LightOnboarding = lazy(() => import('./pages/onboarding'));
const dpoOnboarding = lazy(() => import('./pages/dpoOnboarding'));

const Error404 = lazy(() => import('./pages/errors/error404'));

const Routes = () => {
	const location = useLocation();

	const pageVariants = {
		initial: {
			opacity: 0,
			scale: 0.99,
		},
		in: {
			opacity: 1,
			scale: 1,
		},
		out: {
			opacity: 0,
			scale: 1.01,
		},
	};

	const pageTransition = {
		type: 'tween',
		ease: 'anticipate',
		duration: 0.4,
	};

	const SuspenseLoading = () => {
		return (
			<Fragment>
				<div className='d-flex align-items-center flex-column vh-100 justify-content-center text-center py-3'>
					<div className='d-flex align-items-center flex-column px-4'>
						<ClipLoader color={'#5383ff'} loading={true} />
					</div>
					<div className='text-muted font-size-xl text-center pt-3'>
						Please wait while we load the page.
					</div>
				</div>
			</Fragment>
		);
	};
	return (
		<ThemeProvider theme={MuiTheme}>
			<AnimatePresence>
				<Suspense fallback={<SuspenseLoading />}>
					<Switch>
						<Redirect exact from='/' to='/onboarding' />

						<Route path={['/onboarding']}>
							<PresentationLayout>
								<Switch location={location} key={location.pathname}>
									<motion.div
										initial='initial'
										animate='in'
										exit='out'
										variants={pageVariants}
										transition={pageTransition}
									>
										<Route
											exact
											path='/onboarding/:dpo'
											component={LightOnboarding}
										/>
										<Route
											exact
											path='/onboarding'
											component={LightOnboarding}
										/>
									</motion.div>
								</Switch>
							</PresentationLayout>
						</Route>

						<Route path={['/dpo-onboarding', '/dpo']}>
							<PresentationLayout>
								<Switch location={location} key={location.pathname}>
									<motion.div
										initial='initial'
										animate='in'
										exit='out'
										variants={pageVariants}
										transition={pageTransition}
									>
										<Route path='/dpo-onboarding' component={dpoOnboarding} />
									</motion.div>
								</Switch>
							</PresentationLayout>
						</Route>
						<Route path={['/success']}>
							<PresentationLayout>
								<Switch location={location} key={location.pathname}>
									<motion.div
										initial='initial'
										animate='in'
										exit='out'
										variants={pageVariants}
										transition={pageTransition}
									>
										<Route path='/success' component={SuccessPage} />
									</motion.div>
								</Switch>
							</PresentationLayout>
						</Route>
						<Route path={['/cancel']}>
							<PresentationLayout>
								<Switch location={location} key={location.pathname}>
									<motion.div
										initial='initial'
										animate='in'
										exit='out'
										variants={pageVariants}
										transition={pageTransition}
									>
										<Route path='/cancel' component={CancelPage} />
									</motion.div>
								</Switch>
							</PresentationLayout>
						</Route>
						<Route component={Error404} />
						{/* <Route path={["/stripe"]}>
							<PresentationLayout>
								<Switch location={location} key={location.pathname}>
									<motion.div
										initial="initial"
										animate="in"
										exit="out"
										variants={pageVariants}
										transition={pageTransition}
									>
										<Route path="/stripe" component={StripeTest} />
									</motion.div>
								</Switch>
							</PresentationLayout>
						</Route> */}
						{/* <Route
              path={[
                '/PagesLogin',
                '/PagesRegister',
                '/PagesRecoverPassword',
                '/PagesError404'
              ]}>
              <MinimalLayout>
                <Switch location={location} key={location.pathname}>
                  <motion.div
                    initial="initial"
                    animate="in"
                    exit="out"
                    variants={pageVariants}
                    transition={pageTransition}>
                    <Route path="/PagesLogin" component={PagesLogin} />
                    <Route path="/PagesRegister" component={PagesRegister} />
                    <Route
                      path="/PagesRecoverPassword"
                      component={PagesRecoverPassword}
                    />
                    <Route path="/PagesError404" component={PagesError404} />
                  </motion.div>
                </Switch>
              </MinimalLayout>
            </Route>

            <Route path={['/DashboardDefault', '/Buttons', '/RegularTables1']}>
              <LeftSidebar>
                <Switch location={location} key={location.pathname}>
                  <motion.div
                    initial="initial"
                    animate="in"
                    exit="out"
                    variants={pageVariants}
                    transition={pageTransition}>
                    <Route
                      path="/DashboardDefault"
                      component={DashboardDefault}
                    />
                    <Route path="/Buttons" component={Buttons} />
                    <Route path="/RegularTables1" component={RegularTables1} />
                  </motion.div>
                </Switch>
              </LeftSidebar>
            </Route> */}
					</Switch>
				</Suspense>
			</AnimatePresence>
		</ThemeProvider>
	);
};

export default Routes;
